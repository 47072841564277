import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from '../Tabs';
import Config, { IConfigMethods } from './Config'
import Invoices, { IInvoicesMethods } from './Invoices'
import Stock from '../../model/Stock';
import AnimatedSaveButton from '../AnimatedSaveButton';
import MarketData from './MarketData';
import { saveMarketData } from '../../actions/CurrentStock'
import IMarketData from '../../model/IMarketData';
import moment from 'moment';
import Mailchimp, { IMailChimpMethods } from './Mailchimp';
import Announcements from './Announcements'
import api, { getCombinedTicker } from '../../api/api';
import { uppercaseFirstLetter } from '../../util/util'
import ConfirmEx from '../ConfirmEx'
import AlertEx from '../AlertEx';
import SaveStockResult from '../../model/SaveStockResult';
import ReportConfig from '../reportConfig/ReportConfig'
import * as Roles from '../../model/Roles';
import { IGlobalState } from '../../model/IGlobalState';
import NotesComponent from './notes/NotesComponent';
import { IAnnouncementsMethods } from './EditAnnouncement';


interface IProps {
    stock?:Stock;
    tab?:string;
    onSubmit:(stock:Stock)=>Promise<SaveStockResult>;        
}


const ConfigTabName:string = 'Config';
const MarketDataTabName:string = 'Market';
const MailchimpTabName:string = 'Mailchimp';
const AnnouncementsTabName:string = 'Announcements';
const ReportsTabName:string = 'Reports';
const InvoicesTabName:string = 'Invoices';
const NotesTabName:string = 'Notes';



const StockForm:React.FC<IProps> = (props:IProps) => {
    const newStock = { 
 
    } as Stock
    const user = useSelector((state: IGlobalState) => state.user)
    const defaultTab = user.roles.hasOwnProperty(Roles.Admin) ? ConfigTabName : ReportsTabName;
    const [ stock, setStock ] = useState(props.stock || newStock)
    const [ marketData, setMarketData ] = useState(props.stock != null ? props.stock.currentMarketData : {} as IMarketData)
    const [ activeTab, setActiveTab ] = useState(uppercaseFirstLetter(props.tab) || defaultTab)
    
    const invoicesPage = React.createRef<IInvoicesMethods>();
    const announcementsPage = React.createRef<IAnnouncementsMethods>();
    const mailchimpPage = React.createRef<IMailChimpMethods>();
    const configPage = React.createRef<IConfigMethods>();
    const dispatch = useDispatch();
   
    const save = async () : Promise<boolean> => {
        let success:boolean = false;
        if(activeTab === ConfigTabName){
            success = await configPage.current!.save();
        }else if(activeTab === MarketDataTabName){            
            await dispatch(saveMarketData(getCombinedTicker(stock!), marketData))
            success = true;
        }else if(activeTab === AnnouncementsTabName){
            success = await announcementsPage.current!.save();
        }else if(activeTab === MailchimpTabName){            
            success = await mailchimpPage.current!.save();
        }else if(activeTab === InvoicesTabName){            
            await invoicesPage.current!.save();
            success = true;
        }
        return success;
    }

    


    

    const onMarketDataChange = (name:string, value:number) => {        
         const m1 = { ...marketData } as any;
         m1[name] = value;         
         setMarketData(m1)
    }



   
    const setActiveTabState= (activeTab:string) => {
        
        setActiveTab(activeTab)
        
        const tokens = window.location.pathname.split('/');
        if(tokens.length > 3){
            tokens[3] = activeTab;
        }else{
            tokens.push(activeTab)
        }
        const newPath = tokens.join('/');
        
        window.history.replaceState( {} , document.title, newPath);
        
    }

    

   


    const onSavedAnnouncement = async () =>  {        
        const toSave = { ...stock };
            
        const tmpStock = await api().getStock(getCombinedTicker(stock));
        toSave.announcements = tmpStock.announcements;

        setStock(toSave);
        
    }

    const resyncAnnouncements = async ()  => {
        
        if (!await ConfirmEx('Please confirm you wish to resync Announcements'))
            return false;
        const result = await api().resyncAnnouncements(getCombinedTicker(stock));
        if(result.success){
            const s1 = { ...stock };
            s1.completedInitialPoll = false;             
            setStock(s1); 
            return true;
        }else{
            await AlertEx(result.errorMessage);
            return false;
        }
        
        
    }

    

    const existing = props.stock != null;
    const hasInvoiceRole = user.roles.hasOwnProperty(Roles.InvoiceTotal)
    const syncInProgress = existing && !stock.completedInitialPoll
    const showSaveButton =  activeTab !== NotesTabName && activeTab !== ReportsTabName && 
     (!props.stock || stock.completedInitialPoll)
     const created = props.stock && props.stock.created && `Created: ${moment(props.stock.created).format('DD MMM YY')}`;

    return (
        <div className="stockPage">
            <div className="space-between">
                <div className="space-between">
                <h1 title={created}> {props.stock ? `${props.stock.ticker}:${props.stock.exchange}` : 'Add New Stock'} </h1>
                
                </div>
                { syncInProgress && <p className="sync">
                    Sync in Progress</p>}
                {showSaveButton && <AnimatedSaveButton onClick={save}  />} 
            </div>
            
            
                
                { user.roles.hasOwnProperty(Roles.Admin) && <>
                
                <Tabs onTabChanged={setActiveTabState} activeTab={activeTab}>

                    <div data-label={ConfigTabName}>
                        <Config 
                        id={stock.id}                    
                        ticker={stock.ticker} 
                        exchange={stock.exchange}
                        ref={configPage}
                        />
                    </div>

                    {existing && <div data-label={MarketDataTabName}>
                        <MarketData marketData={marketData} onChange={onMarketDataChange} id={stock.id} />
                    </div>}
                    {existing && <div data-label={MailchimpTabName}>
                        <Mailchimp 
                        ticker={getCombinedTicker(stock)} 
                        apiKey={stock.apiKey}
                        stockId={stock.id}
                        ref={mailchimpPage}
                        />
                    </div>}

                    {existing && <div data-label={AnnouncementsTabName}>
                        <Announcements
                            announcements={stock.announcements}
                            syncInProgress={syncInProgress}
                            ref={announcementsPage}
                            onSavedAnnouncement={onSavedAnnouncement}
                            resyncAnnouncements={resyncAnnouncements}
                        />
                    </div>}



                    {existing && hasInvoiceRole && <div data-label={InvoicesTabName}>
                        <Invoices ticker={getCombinedTicker(stock)} stockId={stock.id} ref={invoicesPage} />
                    </div>}

                    {existing && <div data-label={NotesTabName}>
                        <NotesComponent ticker={getCombinedTicker(stock)} />
                    </div>}

                    { existing && <div data-label={ReportsTabName}>
                            <ReportConfig ticker={getCombinedTicker(stock)} />
                        </div>}

                </Tabs>
                </>}

                { !user.roles.hasOwnProperty(Roles.Admin) && user.roles.hasOwnProperty(Roles.RunReports) && <>
                    <Tabs onTabChanged={setActiveTabState} activeTab={activeTab}>
                  
                    { existing && [ <div data-label={ReportsTabName} key={1}>
                            <ReportConfig ticker={getCombinedTicker(stock)} />
                        </div>]}
                    </Tabs>
                </>}
                
                

            
           
        </div>
    )
    
}




export default StockForm